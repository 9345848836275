<template>
    <div class="modal fade" id="modal-timeline">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header px-5 py-4 mb-4">
                    <button type="button" class="position-absolute btn btn-light p-3 ps-r-0 ps-t-0 m-3" data-dismiss="modal">
                        <i class="far fa-times mx-1"></i>
                    </button>
                </div>
                
                <hr class="m-0">
                <div class="modal-body">
                    <div class="scroll-this">
                        <div class="position-relative mx-4">
                            <div v-if="origin === 'history'">
                                <span class="position-absolute ps-b-0 ps-t-0 ps-l-0 border-left bg-dark"></span>
                                <div class="py-4 pl-5 position-relative"
                                    v-for="(log, index) in timeline_data.logs"
                                    :key="`${log.user.name}${index}`"
                                >
                                    <span 
                                        class="dot-log position-absolute p-2 rounded-circle mt-2"
                                        :class="{
                                            'bg-primary': index === 0,
                                            'bg-secondary': index > 0
                                        }"
                                    ></span>
                                    <div class="row">
                                        <template v-if="log.status === 'Agendado' || log.status === 'Encaixe'">
                                            <div class="col-3">
                                                <span class="text-dark font-14">
                                                    <b>{{log.status.toUpperCase()}}</b>
                                                </span>
                                            </div> 
                                            <div class="col-9">
                                                <span class="text-dark">
                                                    {{ FormatDate(log.data, 'DD/MM/YYYY HH:mm') }}
                                                </span>
                                            </div>
                                            <template v-if="log.description">
                                                <div class="col-3">
                                                    <span class="text-muted font-14">Descrição</span>
                                                </div>
                                                <div class="col-9">
                                                    <span class="text-dark">{{ log.description }}</span>
                                                </div>
                                            </template>
                                            <div class="col-3">
                                                <span class="text-muted font-14">Nome</span>
                                            </div>
                                            <div class="col-9">
                                                <span class="text-dark">{{log.user.name}}</span>
                                            </div>
                                            <div class="col-3">
                                                <span class="text-muted font-14">Procedimento</span>
                                            </div>
                                            <div class="col-9">
                                                <span class="text-dark">{{ log.proceeding ? log.proceeding : 'Sem procedimento vinculado' }}</span>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="col-3">
                                                <span class="text-dark font-14">
                                                    <b>{{ log.status.toUpperCase() }}</b>
                                                </span>
                                            </div> 
                                            <div class="col-9">
                                                <span class="text-dark">
                                                    {{ FormatDate(log.data, 'DD/MM/YYYY HH:mm') }}
                                                </span>
                                            </div>
                                            <template v-if="log.logType === 'user' && log.status !== 'Espera' && log.status !== 'Falta'">
                                                <template v-if="log.description">
                                                    <div class="col-3">
                                                        <span class="text-muted font-14">Descrição</span>
                                                    </div>
                                                    <div class="col-9">
                                                        <span class="text-dark">{{ log.description }}</span>
                                                    </div>
                                                </template>
                                                <div class="col-3">
                                                    <span class="text-muted font-14">Nome</span>
                                                </div>
                                                <div class="col-9">
                                                    <span class="text-dark">{{log.user.name}}</span>
                                                </div>
                                                <div class="col-3">
                                                    <span class="text-muted font-14">Procedimento</span>
                                                </div>
                                                <div class="col-9">
                                                    <span class="text-dark">{{ log.proceeding ? log.proceeding : 'Sem procedimento vinculado'}}</span>
                                                </div>
                                            </template>
                                            <template v-else-if="log.status === 'Espera' || log.status === 'Falta'">
                                                <div class="col-3">
                                                    <span class="text-muted font-14">Nome</span>
                                                </div>
                                                <div class="col-9">
                                                    <span class="text-dark">INSTANT</span>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="col-3">
                                                    <span class="text-muted font-14">Nome</span>
                                                </div>
                                                <div class="col-9">
                                                    <span class="text-dark">{{log.user.name}}</span>
                                                </div>
                                                <template v-if="log.proceeding">
                                                    <div class="col-3">
                                                        <span class="text-muted font-14">Procedimento</span>
                                                    </div>
                                                    <div class="col-9">
                                                        <span class="text-dark">{{ log.proceeding }}</span>
                                                    </div>
                                                </template>
                                            </template>
                                        </template>
                                    </div>					
                                </div>
                            </div>
                            <scheduling-card v-if="timeline_data.journey_id" :journey_id="timeline_data.journey_id"/>
                        </div>
                    </div>
                    <hr class="m-0">
                    <div class="d-flex justify-content-end p-4">
                        <button 
                            class="btn btn-primary p-3 px-4"
                            @click="PrintTimeline"
                        >
                            imprimir
                            <i class="far fa-print ml-3"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

const moment 	= require('moment')

import SchedulingCard from '../search/SchedulingCard'
export default {
    name: "SchedulingModal",
    components: {
        SchedulingCard
    },
    props: {
        timeline_data: 	{
            type:           Object,
            default:        () => {}
        },
        origin:         {
            type:           String,
            default:        ''
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        PrintTimeline()
        {
            const vm  = this
            
            const fn  = (event) => {
                if (event.data.print)
                {
                    window.removeEventListener('message', fn)

                    win.close()
                }
            }

            const logs = vm.origin === 'history' ? '/logs' : ''

            const win     	= window.open(`/pesquisa/${vm.timeline_data.journey_id}/imprimir${logs}`, '_blank')
            win.mainWindow  = window
            window.addEventListener('message', fn)
        },
        FormatDate(value, fmt)
        {
            const m   = moment(value, 'YYYY-MM-DD HH:mm:ss')

            return m.format(fmt)
        },
    }
}
</script>

<style lang="css" scoped>
    .modal-lg {
        max-width: 850px;
    }

    .dot-log {
        left: -5px;
    }
</style>