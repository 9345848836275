<template>
	<div class="col-12 my-5 pr-4 overflow-auto">
		<div class="d-flex flex-column align-items-center">
			<div class="col-12 px-0 d-flex align-items-center my-3">
				<div class="col px-0 mb-0 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="date_init"
					>
						Data Inicial
					</label>
					<input
						id="date_init"
						ref="date_init"
						type="date"
						class="col-9 form-control"
						v-model="record.date_init"
					>
				</div>
				<div class="col px-0 mb-0 ml-4 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="time_init"
					>
						Hora Inicial
					</label>
					<input
						id="time_init"
						ref="time_init"
						type="tel"
						class="col-9 form-control"
						:class="{ 'is-invalid': $v.record.time_init.$error }"
						v-mask="[ '##:##:##' ]"
						placeholder="00:00:00"
						v-model="$v.record.time_init.$model"
					>
					<div class="position-absolute ps-b--4 ps-l-3 invalid-feedback" v-if="!$v.record.time_init.required">
						A hora inicial é obrigatória.
					</div>
					<div class="position-absolute ps-b--4 ps-l-3 invalid-feedback" v-else-if="!$v.record.time_init.validTime">
						{{ $v.record.time_init.$params.validTime.message }}
					</div>
				</div>
			</div>
			<div class="col-12 px-0 d-flex align-items-center my-3">
				<div class="col px-0 mb-0 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="date_end"
					>
						Data Final
					</label>
					<input
						id="date_end"
						ref="date_end"
						type="date"
						class="col-9 form-control"
						v-model="record.date_end"
					>
				</div>
				<div class="col px-0 mb-0 ml-4 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="time_end"
					>
						Hora Final
					</label>
					<input
						id="time_end"
						ref="time_end"
						type="tel"
						class="col-9 form-control"
						:class="{ 'is-invalid': $v.record.time_end.$error }"
						v-mask="[ '##:##:##' ]"
						placeholder="00:00:00"
						v-model="$v.record.time_end.$model"
					>
					<div class="position-absolute ps-b--4 ps-l-3 invalid-feedback" v-if="!$v.record.time_end.required">
						A hora final é obrigatória.
					</div>
					<div class="position-absolute ps-b--4 ps-l-3 invalid-feedback" v-else-if="!$v.record.time_end.validTime">
						{{ $v.record.time_end.$params.validTime.message }}
					</div>
				</div>
			</div>
			<div class="col-12 px-0 d-flex align-items-center my-3">
				<div class="col px-0 mb-0 d-flex align-items-center form-group">
					<label
						class="col-4"
						for="module_name"
					>
						Módulo
					</label>
					<input
						id="module_name"
						ref="module_name"
						type="text"
						class="col form-control"
						v-model="record.module_name"
						disabled
					>
				</div>
				<div class="col px-0 mb-0 d-flex align-items-center form-group">
					<label
						class="col-4 ml-4"
						for="modality_name"
					>
						Modalidade
					</label>
					<input
						id="modality_name"
						ref="modality_name"
						type="text"
						class="col form-control"
						v-model="record.modality_name"
						disabled
					>
				</div>
				<div class="col px-0 mb-0 d-flex align-items-center form-group">
					<label
						class="col-4 ml-4"
						for="schedule_name"
					>
						Agenda
					</label>
					<input
						id="schedule_name"
						ref="schedule_name"
						type="text"
						class="col form-control"
						v-model="record.schedule_name"
					>
				</div>
			</div>
			<div class="col-12 px-0 d-flex align-items-center my-3">
				<div class="col px-0 mb-0 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="patient_name"
					>
						Paciente
					</label>
					<input
						id="patient_name"
						ref="patient_name"
						type="text"
						class="col-9 form-control"
						v-model="record.patient_name"
					>
				</div>
				<div class="col px-0 mb-0 ml-4 d-flex align-items-center form-group position-relative">
					<label
						class="col-3"
						for="patient_cpf"
					>
						CPF
					</label>
					<input
						id="patient_cpf"
						ref="patient_cpf"
						type="tel"
						class="col-9 form-control"
						:class="{ 'is-invalid': $v.record.patient_cpf.$error }"
						v-mask="[ '###.###.###-##' ]"
						v-model="$v.record.patient_cpf.$model"
					>
					<div class="position-absolute ps-b--4 ps-l-3 invalid-feedback" v-if="!$v.record.patient_cpf.ValidateDocument">
						O CPF do paciente deve ser válido.
					</div>
				</div>
			</div>
			<div class="col-12 px-0 d-flex align-items-center my-3">
				<div class="col px-0 mb-0 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="patient_rg"
					>
						RG
					</label>
					<input
						id="patient_rg"
						ref="patient_rg"
						type="text"
						class="col-9 form-control"
						v-model="record.patient_rg"
					>
				</div>
				<div class="col px-0 mb-0 ml-4 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="patient_rg"
					>
						UF
					</label>
					<select 
						id="patient_rg_uf" 
						ref="patient_rg_uf"
						class="form-control"
						v-model="record.patient_rg_uf"
					>
						<option value="">UF</option>
						<option
							v-for="state in states"
							:key="state.initials"
							:value="state.initials"
						>
							{{ state.initials }}
						</option>
					</select>
				</div>
			</div>
			<div class="col-12 px-0 d-flex align-items-center my-3">
				<div class="col px-0 mb-0 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="patient_sus"
					>
						SUS
					</label>
					<input
						id="patient_sus"
						ref="patient_sus"
						type="tel"
						class="col-9 form-control"
						v-model="record.patient_sus"
					>
				</div>
				<div class="col px-0 mb-0 ml-4 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="patient_rh"
					>
						RH
					</label>
					<input
						id="patient_rh"
						ref="patient_rh"
						type="tel"
						class="col-9 form-control"
						v-model="record.patient_rh"
					>
				</div>
			</div>
			<div class="col-12 px-0 d-flex align-items-center my-3">
				<div class="col px-0 mb-0 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="partnership_name"
					>
						Convênio
					</label>
					<input
						id="partnership_name"
						ref="partnership_name"
						type="text"
						class="col-9 form-control"
						v-model="record.partnership_name"
					>
				</div>
				<div class="col px-0 mb-0 ml-4 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="indicator_doctor"
					>
						Solicitante
					</label>
					<input
						id="indicator_doctor"
						ref="indicator_doctor"
						type="tel"
						class="col-9 form-control"
						v-model="record.indicator_doctor"
					>
				</div>
			</div>
			<div class="col-12 px-0 d-flex align-items-center my-3">
				<div class="col px-0 mb-0 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="user_name"
					>
						Autor
					</label>
					<input
						id="user_name"
						ref="user_name"
						type="text"
						class="col-9 form-control"
						v-model="record.user_name"
					>
				</div>
				<div class="col px-0 mb-0 ml-4 d-flex align-items-center form-group position-relative">
					<label
						class="col-3"
						for="document_user"
					>
						Ident. Autor
					</label>
					<input
						id="document_user"
						ref="document_user"
						type="tel"
						class="col-9 form-control"
						:class="{ 'is-invalid': $v.record.document_user.$error }"
						v-mask="[ '###.###.###-##' ]"
						v-model="$v.record.document_user.$model"
					>
					<div class="position-absolute ps-b--4 ps-l-3 invalid-feedback" v-if="!$v.record.document_user.required">
						A indentificação do autor é obrigatória.
					</div>
					<div class="position-absolute ps-b--4 ps-l-3 invalid-feedback" v-else-if="!$v.record.document_user.ValidateDocument">
						A indentificação do autor deve ser válida.
					</div>
				</div>
			</div>
			<div class="col-12 px-0 d-flex align-items-center my-3">
				<div class="col px-0 mb-0 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="released_proceeding"
					>
						Procedimento
					</label>
					<input
						id="released_proceeding"
						ref="released_proceeding"
						type="text"
						class="col-9 form-control"
						v-model="record.released_proceeding"
					>
				</div>
				<div class="col px-0 mb-0 ml-4 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="released_internal_code"
					>
						Cód. Procedimento
					</label>
					<input
						id="released_internal_code"
						ref="released_internal_code"
						type="tel"
						class="col-9 form-control"
						v-model="record.released_internal_code"
					>
				</div>
			</div>
			<div class="col-12 px-0 d-flex align-items-center my-3">
				<div class="col px-0 mb-0 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="authorization"
					>
						Autorização
					</label>
					<input
						id="authorization"
						ref="authorization"
						type="text"
						class="col-9 form-control"
						v-model="record.authorization"
					>
				</div>
				<div class="col px-0 mb-0 ml-4 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="proceeding_value"
					>
						Valor
					</label>
					
					<MoneyInput
						id="proceeding_value"
						ref="proceeding_value"
						custom_class="col-9 form-control"
						v-model="record.proceeding_value"
					/>
				</div>
			</div>
			<div class="col-12 px-0 d-flex align-items-center my-3">
				<div class="col px-0 mb-0 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="scheduled_proceeding"
					>
						Procedimento Agendado
					</label>
					<input
						id="scheduled_proceeding"
						ref="scheduled_proceeding"
						type="text"
						class="col-9 form-control"
						v-model="record.scheduled_proceeding"
					>
				</div>
				<div class="col px-0 mb-0 ml-4 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="scheduled_proceeding_code"
					>
						Cód. Procedimento Agendado
					</label>
					<input
						id="scheduled_proceeding_code"
						ref="scheduled_proceeding_code"
						type="tel"
						class="col-9 form-control"
						v-model="record.scheduled_proceeding_code"
					>
				</div>
			</div>
			<div class="col-12 px-0 d-flex align-items-center my-3">
				<div class="col px-0 mb-0 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="payment_methods"
					>
						Formas de Pagamento
					</label>
					<input
						id="payment_methods"
						ref="payment_methods"
						type="text"
						class="col-9 form-control"
						v-model="record.payment_methods"
					>
				</div>
				<div class="col px-0 mb-0 ml-4 d-flex align-items-center form-group">
					<label
						class="col-3"
						for="payment_value"
					>
						Valor Pago
					</label>
					<input
						id="payment_value"
						ref="payment_value"
						type="tel"
						class="col-9 form-control"
						v-model="record.payment_value"
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash'
import MoneyInput from '../../shared/MoneyInput.vue'
import { helpers, required, requiredIf } from 'vuelidate/lib/validators'
import moment from 'moment'

const validTime = helpers.withParams(
	{ message: 'O valor de hora deve ser válido no formato HH:MM:SS.' },
	(value) => {
		if (!value) return false;
		const timeParts = value.split(':');
		if (timeParts.length !== 3) return false;

		const hours = parseInt(timeParts[0], 10);
		const minutes = parseInt(timeParts[1], 10);
		const seconds = parseInt(timeParts[2], 10);

		return (
		hours >= 0 && hours < 24 &&
		minutes >= 0 && minutes < 60 &&
		seconds >= 0 && seconds < 60
		);
	}
)

const ValidateDocument = (value, vm) => {
	if(value) {
		let ret 		= false
		let cpf 		= value

		if(cpf == null || cpf == '') {
			ret 	= true
		} else if(cpf != null && cpf.length < 14) {
			ret 	= false
		} else if(cpf != null && cpf.length == 14) {
			ret 	= window.helpers.ValidaCPF(cpf)
		}

		return ret
	}

	return true
}

export default {
	name: 'RecordForm',
	components: {
		MoneyInput
	},
	props: {
		props_record: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			states: window.statesBR,
			record: {
				date_init: null,
				time_init: null,
				date_end: null,
				time_end: null,
				module_name: null,
				modality_name: null,
				patient_name: null,
				patient_cpf: null,
				patient_rg: null,
				patient_rg_uf: null,
				patient_sus: null,
				patient_rh: null,
				partnership_name: null,
				indicator_doctor: null,
				user_name: null,
				document_user: null,
				released_proceeding: null,
				released_internal_code: null,
				authorization: null,
				proceeding_value: 0,
				scheduled_proceeding: null,
				scheduled_proceeding_code: null,
				payment_methods: null,
				payment_value: null
			}
		}
	},
	validations() {
		return {
			record: {
				time_init: {
					required,
					validTime
				},
				time_end: {
					required,
					validTime
				},
				patient_cpf: {
					ValidateDocument
				},
				document_user: {
					required,
					ValidateDocument
				}
			}
		}
	},
	watch: {
		props_record: {
			handler(val) {
				if(!_.isEmpty(val)) {
					for (const [ key, value ] of Object.entries(val)) {
						this.record[key] = value == '-' ? null : value
					}

					this.SanitizeRecordData()
				}
			},
			immediate: true
		}
	},
	methods: {
		SanitizeRecordData() {
			this.record.date_init = moment(this.record.date_init, 'DD/MM/YYYY').format('YYYY-MM-DD')
			this.record.date_end = moment(this.record.date_end, 'DD/MM/YYYY').format('YYYY-MM-DD')

			this.record.proceeding_value = Number(this.record.proceeding_value.replace(',', '.'))

			this.record.patient_rg_uf = this.record.patient_rg.match(/.{2}$/)[0]
			this.record.patient_rg = this.record.patient_rg.replace(/[^0-9]/g, '')
		}
	}
}
</script>

<style scoped>
	.hr-width {
		width: 100%
	}

	.gap-5 {
		gap: 1.25rem;
	}
</style>